import { formatTime } from "./formatter"

const jugsaxonyBaseUrl = "https://backoffice.jugsaxony.org/api/"

//const jugsaxonyBaseUrl = "http://localhost:9060/api/"

export const CURRENT_KEY_JSD = "JSD2025"
export const CURRENT_KEY_JSC = "JSC2023"

export function getProgramData(confKey) {
  const url = "https://backoffice.jug-saxony-day.org/api/lineup/app/Dw4NCwoIDw4OAAsMBAoADw1?confKey=" + confKey
  return fetch(url)
}

export function getEventData(key) {
  let url = jugsaxonyBaseUrl + "events"
  if (key) {
    url = url + "?key=" + key
  }
  return fetch(url)
}

export function postEventAttendee(eventId, attendee) {
  const url = jugsaxonyBaseUrl + "events/" + eventId + "/attendees"
  return fetch(url,
    {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(attendee),
    },
  )
}

export function getIcsLink(eventId) {
  const icsLink = jugsaxonyBaseUrl + "events/" + eventId + "/ical/dl"
  return icsLink
}

function getObjectByKey(key, obj) {
  let element
  if (obj.hasOwnProperty(key)) {
    element = obj[key]
  }
  return element

}

function compare(a, b) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export function compareSlotsByStartTime(s1, s2) {
  if (s1.startDateTime && s2.startDateTime) {
    const date1 = new Date(s1.startDateTime)
    const date2 = new Date(s2.startDateTime)
    return compare(date1.getTime(), date2.getTime())
  } else if (s1.startTime && s2.startTime) {
    const timeString1 = s1.startTime.padStart(5, "0")
    const timeString2 = s2.startTime.padStart(5, "0")
    return compare(timeString1, timeString2)
  } else {
    return 0
  }
}

export function compareTracks(a, b) {
  const aNum = parseInt(a.track.replace("T", ""))
  const bNum = parseInt(b.track.replace("T", ""))
  return compare(aNum, bNum)
}

function getTime(time) {
  const duration = parseInt(time.durationInMinutes)

  const startTimeDate = new Date(time.startDateTime)
  const endTimeDate = new Date(startTimeDate.getTime() + duration * 60000)
  let options = { weekday: "short" }
  let germanWeekday = new Intl.DateTimeFormat("de-DE", options).format(startTimeDate)

  return germanWeekday + ", " + formatTime(startTimeDate) + " - " + formatTime(endTimeDate)
}

function getSpeakers(speakers, presentationSpeakers) {
  let speakerNames = []
  presentationSpeakers.forEach((speaker) => {
    if (speaker) {
      const speakerName = getObjectByKey(speaker, speakers)
      speakerNames.push(speakerName)
    }
  })
  return speakerNames
}

export function getPresentationsBySlot(data) {
  let timeSlots = {}

  if (data.schedule) {
    const schedule = data.schedule
    const presentations = schedule.presentations
    const rooms = schedule.rooms
    const speakers = schedule.speakers
    const slots = schedule.slots
    const tracks = schedule.tracks

    Object.entries(slots)
      .sort((e1, e2) => compareSlotsByStartTime(e1[1], e2[1]))
      .forEach(slotEntry => timeSlots[slotEntry[0]] = [])

    Object.values(presentations).forEach(presentation => {
      const slot = presentation.slot
      let room = getObjectByKey(presentation.room, rooms)
      const roomName = room ? room.name : ""
      const time = getTime(getObjectByKey(presentation.slot, slots))
      const speakerNames = getSpeakers(speakers, presentation.speakers)
      const track = getObjectByKey(presentation.track, tracks)
      presentation.roomName = roomName
      presentation.speakerNames = speakerNames
      presentation.time = time
      presentation.trackName = track.name
      presentation.trackColor = track.themeColor
      if (timeSlots.hasOwnProperty(slot)) {
        timeSlots[slot] = [...timeSlots[slot], presentation]
      } else {
        timeSlots[slot] = [presentation]
      }
    })
  }
  Object.values(timeSlots).forEach((val) => val.sort(compareTracks))
  return timeSlots
}

export function getPresentationById(data, id) {
  let result = {}
  if (data.schedule && id) {
    const schedule = data.schedule
    const presentations = schedule.presentations
    const rooms = schedule.rooms
    const speakers = schedule.speakers
    const slots = schedule.slots
    const tracks = schedule.tracks
    Object.values(presentations).forEach(presentation => {
      if (presentation.id.toString() === id.toString()) {
        const roomName = getObjectByKey(presentation.room, rooms).name
        const time = getTime(getObjectByKey(presentation.slot, slots))
        const speakerNames = getSpeakers(speakers, presentation.speakers)
        const track = getObjectByKey(presentation.track, tracks)
        presentation.roomName = roomName
        presentation.speakerNames = speakerNames
        presentation.time = time
        presentation.trackName = track.name
        presentation.trackColor = track.themeColor
        result = presentation
      }
    })
  }

  return result
}


export function getJobs() {
  const url = jugsaxonyBaseUrl + "job-wall/job-offers"
  return fetch(url)
}

export function getJobFilters(allJobs, filterType) {
  const jobTypes = []
  allJobs.forEach(job => {
    const currentJobType = job[filterType]
    if (!jobTypes.includes(currentJobType)) {
      jobTypes.push(currentJobType)
    }
  })
  return jobTypes
}

export function getJobwallImageUrl(imgUrl) {
  const imageUrlPath = "job-wall/job-offers/images/"
  return jugsaxonyBaseUrl + imageUrlPath + imgUrl
}

export function getJobPreview(key) {
  const url = jugsaxonyBaseUrl + "job-wall/preview?key=" + key
  return fetch(url)
}

import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ContentContainer from "../../../components/contentContainer"
import Loader from "../../../components/loader"

import { RiArrowDownSLine } from "@react-icons/all-files/ri/RiArrowDownSLine"
import { FaFilter } from "@react-icons/all-files/fa/FaFilter"
import { RiSuitcaseFill } from "@react-icons/all-files/ri/RiSuitcaseFill"
import { MdPlace } from "@react-icons/all-files/md/MdPlace"
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose"

import { getJobFilters, getJobs, getJobwallImageUrl } from "../../../helpers/apiService"
import { getJobwallDetailLink } from "../../../helpers/formatter"

import * as jobwallStyles from "../../../styles/jobwall.module.scss"

class Jobs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allJobs: [],
      loading: true,
      error: false,
      filteredJobs: [],
      locationFilters: [],
      typeFilters: [],
      employerFilters: [],
      jobSorters: [
        "Firma (alphabetisch aufsteigend)",
        "Firma (alphabetisch absteigend)",
        "Beschäftigungstitel (alphabetisch aufsteigend)",
        "Beschäftigungstitel (alphabetisch absteigend)",
        "Aktualität (aufsteigend)",
        "Aktualität (absteigend)",
        "Beschäftigungsform (alphabetisch aufsteigend)",
        "Beschäftigungsform (alphabetisch absteigend)",
        "Ort (alphabetisch aufsteigend)",
        "Ort (alphabetisch absteigend)",
      ],
      activeMobileFilter: "Firmen",
      currentLocationFilter: "",
      currentTypeFilter: "",
      currentEmployerFilter: "",
      currentJobSorter: "",
      showFilters: false,
    }

    this.onSelect = this.onSelect.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.filter = this.filter.bind(this)
    this.sort = this.sort.bind(this)
    this.setShowFilters = this.setShowFilters.bind(this)
    this.applyFiltersAndSorters = this.applyFiltersAndSorters.bind(this)
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search)
    const currentTypeFilter = queryParams.get("type") || ""
    const currentEmployerFilter = queryParams.get("company") || ""
    const currentLocationFilter = queryParams.get("location") || ""
    const sortParam = queryParams.get("sort") || ""
    const currentJobSorter = this.getJobSorterForUrlParam(sortParam)
    getJobs().then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error()
      }
    }).then(response => {
      const locations = getJobFilters(response, "jobLocation")
      const types = getJobFilters(response, "jobType")
      const employers = getJobFilters(response, "companyName").sort((a, b) => {
        if(a.toLowerCase() < b.toLowerCase()) return -1
        else if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })

      if (currentEmployerFilter !== "" && !employers.includes(currentEmployerFilter)) {
        employers.push(currentEmployerFilter)
      }
      if (currentLocationFilter !== "" && !locations.includes(currentLocationFilter)) {
        locations.push(currentLocationFilter)
      }
      if (currentTypeFilter !== "" && !types.includes(currentTypeFilter)) {
        types.push(currentTypeFilter)
      }

      this.setState({
          allJobs: response,
          loading: false,
          filteredJobs: response,
          locationFilters: locations,
          typeFilters: types,
          employerFilters: employers,
          currentTypeFilter: currentTypeFilter,
          currentLocationFilter: currentLocationFilter,
          currentEmployerFilter: currentEmployerFilter,
          currentJobSorter: currentJobSorter,
        },
        () => {
          this.applyFiltersAndSorters()
        })
    })
      .catch(error => {
        this.setState({ loading: false, error: true })
      })
  }

  getJobSorterForUrlParam(sortParam) {
    if (sortParam === "latest") {
      return "Aktualität (absteigend)"
    } else if (sortParam === "oldest") {
      return "Aktualität (aufsteigend)"
    } else if (sortParam === "company") {
      return "Firma (alphabetisch aufsteigend)"
    }

    return ""
  }

  onSelect(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.applyFiltersAndSorters()
    })
  }

  clearFilter() {
    const { allJobs } = this.state
    this.setState({
      currentLocationFilter: "", currentTypeFilter: "", currentEmployerFilter: "",
      currentJobSorter: "", filteredJobs: allJobs,
    })
  }

  applyFiltersAndSorters() {
    const { allJobs } = this.state
    const filteredJobs = this.filter(allJobs)
    const sortedJobs = this.sort(filteredJobs)
    this.setState({ filteredJobs: sortedJobs })
  }

  filter(jobs) {
    const { currentLocationFilter, currentTypeFilter, currentEmployerFilter } = this.state
    const filteredJobs = jobs.filter((job) => {
      let fulfillsFilterCriteria = true
      if ((currentLocationFilter && currentLocationFilter != "" && job.jobLocation && currentLocationFilter.toLowerCase() != job.jobLocation.toLowerCase())) {
        fulfillsFilterCriteria = false
      }
      if ((currentTypeFilter && currentTypeFilter != "" && job.jobType && currentTypeFilter.toLowerCase() != job.jobType.toLowerCase())) {
        fulfillsFilterCriteria = false
      }
      if ((currentEmployerFilter && currentEmployerFilter != "" && job.companyName && currentEmployerFilter.toLowerCase() != job.companyName.toLowerCase())) {
        fulfillsFilterCriteria = false
      }
      return fulfillsFilterCriteria
    })
    return filteredJobs
  }

  sort(jobs) {
    const { currentJobSorter } = this.state

    if (!currentJobSorter || currentJobSorter == "") {
      return jobs
    }

    const sortFunction = (a, b) => {
      let sortValue = ""
      let asc = true
      let isSortingCharacters = true

      if (currentJobSorter === "Firma (alphabetisch aufsteigend)") {
        sortValue = "companyName"
      } else if (currentJobSorter === "Firma (alphabetisch absteigend)") {
        sortValue = "companyName"
        asc = false
      } else if (currentJobSorter === "Beschäftigungsform (alphabetisch aufsteigend)") {
        sortValue = "jobType"
      } else if (currentJobSorter === "Beschäftigungsform (alphabetisch absteigend)") {
        sortValue = "jobType"
        asc = false
      } else if (currentJobSorter === "Beschäftigungsform (alphabetisch aufsteigend)") {
        sortValue = "jobType"
      } else if (currentJobSorter === "Beschäftigungsform (alphabetisch absteigend)") {
        sortValue = "jobType"
        asc = false
      } else if (currentJobSorter === "Beschäftigungstitel (alphabetisch aufsteigend)") {
        sortValue = "jobTitle"
      } else if (currentJobSorter === "Beschäftigungstitel (alphabetisch absteigend)") {
        sortValue = "jobTitle"
        asc = false
      } else if (currentJobSorter === "Ort (alphabetisch aufsteigend)") {
        sortValue = "jobLocation"
      } else if (currentJobSorter === "Ort (alphabetisch absteigend)") {
        sortValue = "jobLocation"
        asc = false
      } else if (currentJobSorter === "Aktualität (aufsteigend)") {
        sortValue = "publishedDate"
        isSortingCharacters = false
      } else if (currentJobSorter === "Aktualität (absteigend)") {
        sortValue = "publishedDate"
        isSortingCharacters = false
        asc = false
      } else {
        return 0
      }

      let valA = a[sortValue]
      let valB = b[sortValue]


      if (!valA || !valB) {
        if (!valA) {
          return -1
        }
        return 1
      }

      if (isSortingCharacters) {
        valA = valA.toLowerCase()
        valB = valB.toLowerCase()
      }

      if (asc) {
        if (valA < valB) return -1
        if (valB < valA) return 1
        return 0
      } else {
        if (valA < valB) return 1
        if (valB < valA) return -1
        return 0
      }
    }

    return jobs.sort(sortFunction)
  }

  isSelected(selectedFilterValue, valueOnFilter) {
    if (!selectedFilterValue || !valueOnFilter) {
      return false
    }
    return selectedFilterValue.toLowerCase() === valueOnFilter.toLowerCase() ? true : false
  }

  getJobSelect(name, id, values, currentValue, defaultValue, onChange) {
    return (
      <div className={jobwallStyles.selectWrapper}>
        <select className={`${jobwallStyles.jobSelect} ${currentValue !== "" ? jobwallStyles.selection : ""}`}
                name={name} id={name} onChange={onChange}>
          <option className={jobwallStyles.placeholder} selected={!currentValue} disabled
                  value="">{defaultValue}</option>
          {values.map((value) => <option selected={this.isSelected(currentValue, value)}
                                         className={jobwallStyles.jobOption} value={value}
                                         key={value}>{value}</option>)}
        </select>
        <div className={jobwallStyles.iconWrapper}></div>
        <div className={jobwallStyles.icon}><RiArrowDownSLine
          style={{ height: "30px", width: "30px", color: "white" }} /></div>
      </div>
    )
  }

  setShowFilters() {
    const { showFilters } = this.state
    this.setState({ showFilters: !showFilters })
  }

  getMobileFiltersToDisplay() {
    const { locationFilters, typeFilters, employerFilters, jobSorters, activeMobileFilter } = this.state
    let mobileFiltersToDisplay = locationFilters
    if (activeMobileFilter === "Firmen") {
      mobileFiltersToDisplay = employerFilters
    } else if (activeMobileFilter === "Beschäftigungsarten") {
      mobileFiltersToDisplay = typeFilters
    } else if (activeMobileFilter === "Sortieren") {
      mobileFiltersToDisplay = jobSorters
    }
    return mobileFiltersToDisplay
  }

  onMobileToplevelFilterClick(type) {
    this.setState({ activeMobileFilter: type })
  }

  onMobileFilterSelect(selectedFilter) {
    const { activeMobileFilter } = this.state
    if (activeMobileFilter === "Firmen") {
      this.setState({ currentEmployerFilter: selectedFilter }, () => {
        this.applyFiltersAndSorters()
      })
    } else if (activeMobileFilter === "Beschäftigungsarten") {
      this.setState({ currentTypeFilter: selectedFilter }, () => {
        this.applyFiltersAndSorters()
      })
    } else if (activeMobileFilter === "Orte") {
      this.setState({ currentLocationFilter: selectedFilter }, () => {
        this.applyFiltersAndSorters()
      })
    } else if (activeMobileFilter === "Sortieren") {
      this.setState({ currentJobSorter: selectedFilter }, () => {
        this.applyFiltersAndSorters()
      })
    }
  }

  resetFilter(type) {
    if (type === "Firmen") {
      this.setState({ currentEmployerFilter: "" }, () => {
        this.applyFiltersAndSorters()
      })
    } else if (type === "Beschäftigungsarten") {
      this.setState({ currentTypeFilter: "" }, () => {
        this.applyFiltersAndSorters()
      })
    } else if (type === "Orte") {
      this.setState({ currentLocationFilter: "" }, () => {
        this.applyFiltersAndSorters()
      })
    } else if (type === "Sortieren") {
      this.setState({ currentJobSorter: "" }, () => {
        this.applyFiltersAndSorters()
      })
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const {
      filteredJobs,
      locationFilters,
      typeFilters,
      employerFilters,
      loading,
      error,
      currentLocationFilter,
      currentTypeFilter,
      currentEmployerFilter,
      showFilters,
      jobSorters,
      currentJobSorter,
      activeMobileFilter,
    } = this.state
    const mobileFiltersToDisplay = this.getMobileFiltersToDisplay()
    const filtersCurrentlySelected = currentLocationFilter !== "" || currentTypeFilter !== "" || currentEmployerFilter !== "" || currentJobSorter !== ""

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Jobwall" />
        <div className="content">
          <ContentContainer>
            {loading && <Loader />}
            {error && <div>Jobs konnten nicht geladen werden</div>}
            {!loading && !error && <div>
              <div
                className={`${jobwallStyles.filterBar} ${showFilters && !filtersCurrentlySelected ? jobwallStyles.filterBarMediumSize : ""} ${showFilters && filtersCurrentlySelected ? jobwallStyles.filterBarLargeSize : ""}`}>
                <div className={`${jobwallStyles.filters}`}>
                  {this.getJobSelect("currentEmployerFilter", "companies", employerFilters, currentEmployerFilter, "Alle Firmen...", this.onSelect)}
                  {this.getJobSelect("currentJobSorter", "sorters", jobSorters, currentJobSorter, "Sortieren nach...", this.onSelect)}
                  {this.getJobSelect("currentTypeFilter", "types", typeFilters, currentTypeFilter, "Alle Beschäftigungsarten...", this.onSelect)}
                  {this.getJobSelect("currentLocationFilter", "places", locationFilters, currentLocationFilter, "Alle Orte...", this.onSelect)}
                </div>
                <button className={jobwallStyles.clearButton} onClick={this.clearFilter}>Filter löschen</button>
                <div className={jobwallStyles.mobileFilters}>
                  <div className={jobwallStyles.mobileAllFiltersSelect}>
                    <div className={jobwallStyles.filterIcon} onClick={this.setShowFilters}><FaFilter /></div>
                    <div
                      className={`${jobwallStyles.mobileJobsFilter} ${jobwallStyles.scrollbarFilters} ${!showFilters ? jobwallStyles.filterBarClosedMobile : ""}`}>
                      <div
                        className={`${jobwallStyles.mobileFilterType} ${jobwallStyles.scrollbarFilter} ${activeMobileFilter === "Firmen" ? jobwallStyles.mobileFilterTypeActive : ""}`}
                        onClick={() => this.onMobileToplevelFilterClick("Firmen")}>Firmen
                      </div>
                      <div
                        className={`${jobwallStyles.mobileFilterType} ${jobwallStyles.scrollbarFilter} ${activeMobileFilter === "Sortieren" ? jobwallStyles.mobileFilterTypeActive : ""}`}
                        onClick={() => this.onMobileToplevelFilterClick("Sortieren")}>Sortieren
                      </div>
                      <div
                        className={`${jobwallStyles.mobileFilterType} ${jobwallStyles.scrollbarFilter} ${activeMobileFilter === "Beschäftigungsarten" ? jobwallStyles.mobileFilterTypeActive : ""}`}
                        onClick={() => this.onMobileToplevelFilterClick("Beschäftigungsarten")}>Beschäftigungsarten
                      </div>
                      <div
                        className={`${jobwallStyles.mobileFilterType} ${jobwallStyles.scrollbarFilter} ${activeMobileFilter === "Orte" ? jobwallStyles.mobileFilterTypeActive : ""}`}
                        onClick={() => this.onMobileToplevelFilterClick("Orte")}>Orte
                      </div>
                    </div>
                    <div
                      className={`${jobwallStyles.scrollbarFilters} ${jobwallStyles.secondLevelFilters} ${!showFilters ? jobwallStyles.filterBarClosedMobile : ""}`}>
                      {mobileFiltersToDisplay.map(mobileFilterToDisplay => {
                        return (
                          <div className={`${jobwallStyles.scrollbarFilter} ${jobwallStyles.secondLevelFilter}`}
                               onClick={() => this.onMobileFilterSelect(mobileFilterToDisplay)}
                               key={`mobile${mobileFilterToDisplay}`}>{mobileFilterToDisplay}</div>
                        )
                      })}
                    </div>
                  </div>
                  <div
                    className={`${jobwallStyles.scrollbarFilters} ${jobwallStyles.selectedFilters} ${!showFilters ? jobwallStyles.selectedFiltersBarClosed : ""}`}>
                    {currentLocationFilter && currentLocationFilter != "" &&
                      <div className={`${jobwallStyles.scrollbarFilter} ${jobwallStyles.selectedFilter}`}>
                        {currentLocationFilter} <span onClick={() => this.resetFilter("Orte")}
                                                      className={jobwallStyles.closeIcon}><AiOutlineClose /></span>
                      </div>}
                    {currentJobSorter && currentJobSorter != "" &&
                      <div className={`${jobwallStyles.scrollbarFilter} ${jobwallStyles.selectedFilter}`}>
                        {currentJobSorter} <span onClick={() => this.resetFilter("Sortieren")}
                                                 className={jobwallStyles.closeIcon}><AiOutlineClose /></span>
                      </div>}
                    {currentTypeFilter && currentTypeFilter != "" &&
                      <div className={`${jobwallStyles.scrollbarFilter} ${jobwallStyles.selectedFilter}`}>
                        {currentTypeFilter} <span onClick={() => this.resetFilter("Beschäftigungsarten")}
                                                  className={jobwallStyles.closeIcon}><AiOutlineClose /></span>
                      </div>}
                    {currentEmployerFilter && currentEmployerFilter != "" &&
                      <div className={`${jobwallStyles.scrollbarFilter} ${jobwallStyles.selectedFilter}`}>
                        {currentEmployerFilter} <span onClick={() => this.resetFilter("Firmen")}
                                                      className={jobwallStyles.closeIcon}><AiOutlineClose /></span>
                      </div>}
                  </div>
                </div>
              </div>
              {!loading && !error && filteredJobs.length === 0 &&
                <div>Es konnten keine passenden Jobs gefunden werden</div>}
              <div className={jobwallStyles.jobsTable}>
                {filteredJobs.map((job => {
                  const link = getJobwallDetailLink(job.companyName, job.jobTitle, job.id)
                  return (
                    <div className={jobwallStyles.jobRow} key={`job${job.id}`}>
                      <Link to={link}>
                        <div className={`${jobwallStyles.tableCell} ${jobwallStyles.imgCell}`}><img
                          className={jobwallStyles.jobLogo} src={getJobwallImageUrl(job.companyLogoImage)} /></div>
                        <div className={`${jobwallStyles.tableCell} ${jobwallStyles.jobDescription}`}>
                          <div className={jobwallStyles.tableContent}>{job.jobTitle}</div>
                        </div>
                        <div className={`${jobwallStyles.tableCell} ${jobwallStyles.jobType}`}>
                          <div className={jobwallStyles.tableContent}>
                            <span className={jobwallStyles.itemIcons}><RiSuitcaseFill /></span>
                            <span className={jobwallStyles.jobTypeContent}>{job.jobType}</span>
                          </div>
                        </div>
                        <div className={`${jobwallStyles.tableCell} ${jobwallStyles.jobLocation}`}>
                          <div className={jobwallStyles.tableContent}>
                            <span className={jobwallStyles.itemIcons}><MdPlace /></span>
                            <span className={jobwallStyles.jobTypeContent}>{job.jobLocation}</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                }))}
              </div>
            </div>}
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Jobs

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
